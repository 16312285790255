/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllRFP = payload => {
  return instance.instanceToken
    .get("/rfp/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetVenueForRFP = payload => {
  return instance.instanceToken
    .post("/rfp/setVenue", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetCityInCharge = payload => {
  return instance.instanceToken
    .post("/rfp/setCityInCharge", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const UpdateRFP = payload => {
  return instance.instanceToken
    .post("/rfp/update", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const UpdateRFPEvent = payload => {
  return instance.instanceToken
    .post("/rfp/updateEventSummery", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const GetIsCityInCharge = payload => {
  return instance.instanceToken
    .post("/rfp/isCityInCharge", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetCityForRFP = payload => {
  return instance.instanceToken
    .post("/rfp/setCity", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetAdminVal = payload => {
  return instance.instanceToken
    .post("/rfp/setAdminVal", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const UploadFile = payload => {
  return instance.instanceFileUpload
    .post("/rfp/upload", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const RemoveSupportDoc = payload => {
  return instance.instanceFileUpload
    .post("/rfp/delete_support_doc", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const UploadSupDoc = payload => {
  return instance.instanceFileUpload
    .post("/rfp/upload_supporting_docs", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const UploadRFPDocs = payload => {
  return instance.instanceFileUpload
    .post("/rfp/uploadRFPDocs", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetStatusForRFP = payload => {
  return instance.instanceToken
    .post("/rfp/setStatus/" + payload.id + "/" + payload.status)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const RemoveVenue = payload => {
  return instance.instanceToken
    .post(
      "/rfp/removeVenue/" +
      payload.id +
      "/" +
      payload.rfpId +
      "/" +
      payload.cityShortListId
    )
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const RemoveCity = payload => {
  return instance.instanceToken
    .post("/rfp/removeCity/" + payload.id + "/" + payload.rfpId)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetSelected = payload => {
  return instance.instanceToken
    .post("/rfp/setSelected", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const GetRFPById = payload => {
  return instance.instanceToken
    .get("/rfp/getById/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetDelete = payload => {
  return instance.instanceToken
    .get("/rfp/delete/" + payload._id)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetCombinedVenue = (payload) => {
  return instance.instanceFileUpload.post("/RFP/setCombinedVenue", payload);
};

export default {
  getRFPById: GetRFPById,
  getAllRFP: GetAllRFP,
  setVenueForRFP: SetVenueForRFP,
  setCityForRFP: SetCityForRFP,
  setStatusForRFP: SetStatusForRFP,
  setAdminVal: SetAdminVal,
  uploadFile: UploadFile,
  removeVenue: RemoveVenue,
  removeCity: RemoveCity,
  setSelected: SetSelected,
  setDelete: SetDelete,
  setCityInCharge: SetCityInCharge,
  getIsCityInCharge: GetIsCityInCharge,
  updateRFP:UpdateRFP,
  updateRFPEvent:UpdateRFPEvent,
  uploadRFPDocs:UploadRFPDocs,
  setCombinedVenue: SetCombinedVenue,
  uploadSupDoc: UploadSupDoc,
  removeSupportDoc: RemoveSupportDoc,
};
